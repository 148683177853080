import ReactDOM from 'react-dom';

// Include UIKit
import UIkit from 'uikit';
import '../node_modules/uikit/dist/css/uikit.min.css';
import Icons from '../node_modules/uikit/dist/js/uikit-icons';

import {makeMainRoutes} from './routes';

const routes = makeMainRoutes();

UIkit.use(Icons);

ReactDOM.render(
    routes,
    document.getElementById("react-container")
);