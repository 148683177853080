import React from 'react';
import {Router, Route, Redirect} from "react-router-dom";

// Site assets
import './img/favicon/favicon.ico';
import './img/favicon/apple-icon-57x57.png';
import './img/favicon/apple-icon-60x60.png';
import './img/favicon/apple-icon-72x72.png';
import './img/favicon/apple-icon-76x76.png';
import './img/favicon/apple-icon-114x114.png';
import './img/favicon/apple-icon-120x120.png';
import './img/favicon/apple-icon-144x144.png';
import './img/favicon/apple-icon-152x152.png';
import './img/favicon/apple-icon-180x180.png';
import './img/favicon/android-icon-192x192.png';
import './img/favicon/favicon-32x32.png';
import './img/favicon/favicon-96x96.png';
import './img/favicon/favicon-16x16.png';

// React components
import App from './scripts/app';
import Callback from './scripts/pages/callback/Callback';
import Auth from './scripts/components/Auth/Auth';
import history from './history';
import Home from './scripts/pages/home/index';
import Identity from './scripts/pages/oxygen/index';
import Forge from './scripts/pages/forge/index';
import Profile from './scripts/pages/profile/index';
import Ping from './scripts/components/Ping/Ping';

const auth = new Auth();

const handleAuthentication = ({location}) => {
    if (/access_token|id_token|error/.test(location.hash)) {
        auth.handleAuthentication();
    }
};

export const makeMainRoutes = () => {
    return (
        <Router history={history}>
            <div>
                <Route path="/" render={(props) => <App auth={auth} {...props} />}/>
                <Route path="/home" render={(props) => <Home auth={auth} {...props} />}/>
                <Route path="/identity" render={(props) => <Identity auth={auth} {...props} />}/>
                <Route path="/forge" render={(props) => <Forge auth={auth} {...props} />}/>
                <Route path="/profile" render={(props) => (
                    !auth.isAuthenticated() ? (
                        <Redirect to="/home"/>
                    ) : (
                        <Profile auth={auth} {...props} />
                    )
                )}/>
                <Route path="/ping" render={(props) => (
                    !auth.isAuthenticated() ? (
                        <Redirect to="/home"/>
                    ) : (
                        <Ping auth={auth} {...props} />
                    )
                )}/>
                <Route path="/callback" render={(props) => {
                    handleAuthentication(props);
                    return <Callback {...props} />
                }}/>
            </div>
        </Router>
    );
};
