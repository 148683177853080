import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';

import './charts.scss';
import Loader from "../loader/loader";

const chartLoadTime = 1000;

class ChartVertBar extends Component {

    state = {
        loading: true,
        data: [],
        labels: []
    };

    componentDidMount() {

        setTimeout(() =>
                this.setState({
                    loading: false,
                    data: [65, 59, 80, 81, 56, 55, 40],
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July']
                })
            , chartLoadTime)

    }

    render() {
        return (
            <div className={this.state.loading ? 'uk-inline' : ''}>
                <Loader loading={this.state.loading}/>
                <div className="uk-padding xd-chart-container"
                     style={{visibility: this.state.loading ? 'hidden' : 'visible'}}>
                    <h5 className={"uk-text-left uk-margin-remove uk-text-bold uk-text-uppercase"}>
                        {this.props.title}
                    </h5>
                    <p className={"uk-text-left uk-margin-remove-top"}>
                        {this.props.subtitle}
                    </p>
                    <Bar data={{
                        labels: this.state.labels,
                        datasets: [
                            {
                                label: 'My First dataset',
                                hoverBackgroundColor: 'rgba(255,255,255,1)',
                                data: this.state.data
                            }
                        ]
                    }} options={{
                        scales: {
                            yAxes: [{
                                ticks: {beginAtZero: true}
                            }]
                        }
                    }}/>
                </div>
            </div>
        )
    }
}

export default ChartVertBar;