import React, {Component} from 'react';
import './index.scss';

import ChartLine from '../../components/charts/line';
import ChartDonut from '../../components/charts/donut';
import ChartHortBar from '../../components/charts/bar_horizontal';
import ChartVertBar from '../../components/charts/bar_vertical';


class About extends Component {

    componentDidMount() {
        document.body.classList.toggle('xd__pages_oxygen');
    }

    componentWillUnmount() {
        document.body.classList.toggle('xd__pages_oxygen')
    }

    render() {
        return (
            <div className="uk-section uk-dark uk-animation-fade uk-padding-remove-top">
                <div className="uk-container-expand uk-align-center">
                    <div
                        className="uk-flex-center uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@xl uk-text-center"
                        data-uk-grid>
                        <div className="uk-width-1-1 uk-height-large">
                            <ChartLine title={"Sessions"} subtitle={"Traffic volume on site"}/>
                        </div>
                        <div className="uk-height-large">
                            <ChartHortBar title={"Countries"} subtitle={"Top 10 countries by users"}/>
                        </div>
                        <div className="uk-height-large">
                            <ChartDonut title={"Devices"} subtitle={"Device breakdown by sessions"}/>
                        </div>
                        <div className="uk-height-large">
                            <ChartVertBar title={"Browsers"} subtitle={"Top 5 browsers by sessions"}/>
                        </div>
                        <div className="uk-height-large">
                            <ChartLine title={"Growth"} subtitle={"Cummulative registrations over time"}/>
                        </div>
                        <div className="uk-height-large">
                            <ChartDonut title={"Registrations"} subtitle={"How users move thru the create account flow"}/>
                        </div>
                        <div className="uk-height-large">
                            <ChartDonut title={"Errors"} subtitle={"Overall errors our users are facing"}/>
                        </div>
                        <div className="uk-height-large">
                            <ChartDonut title={"2FA Setups"} subtitle={"How users are setting up 2FA through login"}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;