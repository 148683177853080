import React, {Component} from 'react';
import {API_URL} from '../Auth/endpoints';
import axios from 'axios';

class Ping extends Component {

    constructor() {
        super();
        this.state = {
            message: ''
        }
    }

    ping() {
        axios.get(`${API_URL}/public`)
            .then(response => this.setState({message: response.data.message}))
            .catch(error => this.setState({message: error.message}));
    }

    securedPing() {
        const {getAccessToken} = this.props.auth;
        const headers = {'Authorization': `Bearer ${getAccessToken()}`}
        axios.get(`${API_URL}/private`, {headers})
            .then(response => this.setState({message: response.data.message}))
            .catch(error => this.setState({message: error.message}));
    }

    securedScopeAndPing() {
        const {getAccessToken} = this.props.auth;
        const headers = {'Authorization': `Bearer ${getAccessToken()}`}
        axios.get(`${API_URL}/getAuthorized`, {headers})
            .then(response => this.setState({message: response.data.message}))
            .catch(error => this.setState({message: error.message}));
    }

    render() {
        const {isAuthenticated} = this.props.auth;
        const {message} = this.state;
        return (
            <div className="container">
                <h1>Make a Call to the Server</h1>
                {
                    !isAuthenticated() &&
                    <p>Log in to call a private (secured) server endpoint.</p>
                }
                <a onClick={this.ping.bind(this)}>Ping</a>
                &nbsp;
                {
                    isAuthenticated() && (
                        <a onClick={this.securedPing.bind(this)}>
                            Call Private
                        </a>
                    )
                }
                &nbsp;

                {
                    isAuthenticated() && (
                        <a onClick={this.securedScopeAndPing.bind(this)}>
                            Call Private Scoping
                        </a>
                    )
                }
                <h2>{message}</h2>
            </div>
        );
    }
}

export default Ping;
