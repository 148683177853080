import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';
import Loader from '../loader/loader';

import './charts.scss';

const chartLoadTime = 1000;

class ChartLine extends Component {

    state = {
        loading: true,
        data: [],
        labels: []
    };

    componentDidMount() {

        setTimeout(() =>
                this.setState({
                    loading: false,
                    data: [65, 59, 80, 81, 56, 55, 40],
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July']
                })
            , chartLoadTime)

    }

    render() {
        return (
            <div className={this.state.loading ? 'uk-inline' : ''}>
                <Loader loading={this.state.loading}/>
                <div className="uk-padding xd-chart-container"
                     style={{visibility: this.state.loading ? 'hidden' : 'visible'}}>
                    <h5 className={"uk-text-left uk-margin-remove uk-text-bold uk-text-uppercase"}>
                        {this.props.title}
                    </h5>
                    <p className={"uk-text-left uk-margin-remove-top"}>
                        {this.props.subtitle}
                    </p>
                    <Line data={{
                        labels: this.state.labels,
                        datasets: [
                            {
                                label: 'My First dataset',
                                fill: false,
                                lineTension: 0.1,
                                borderCapStyle: 'butt',
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: 'miter',
                                pointBorderColor: 'rgba(75,192,192,1)',
                                pointBackgroundColor: '#fff',
                                pointBorderWidth: 1,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                                pointHoverBorderColor: 'rgba(220,220,220,1)',
                                pointHoverBorderWidth: 2,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: this.state.data
                            }
                        ]
                    }}/>
                </div>
            </div>

        )
    }
}

export default ChartLine;