import React from 'react';
import './Callback.scss';

const Callback = () => {
    return (
        <div className={'spinner-container'}>
            <div className={'uk-light'} data-uk-spinner="ratio: 3"></div>
        </div>
    );
};

export default Callback;