import React, {Component} from 'react';
import './index.scss';
import {API_URL} from '../../components/Auth/endpoints';
import axios from 'axios';

class Forge extends Component {

    constructor() {
        super();
        this.state = {
            message: ''
        }
    }

    componentDidMount() {
        document.body.classList.toggle('xd__pages_forge');
        this.getForgeData()
    }

    componentWillUnmount() {
        document.body.classList.toggle('xd__pages_forge')
    }

    getForgeData() {
        axios.get(`${API_URL}/getForgeAnalytics`,)
            .then(response => this.setState({message: response.data.message}))
            .catch(error => this.setState({message: error.message}));
    }

    render() {
        const {isAuthenticated} = this.props.auth;
        const {message} = this.state;
        return (
            <div className={"uk-section uk-dark uk-animation-fade"}>
                <div className="uk-container">

                    {
                        !isAuthenticated() &&
                        <h1>
                            None
                        </h1>
                    }

                    {
                        isAuthenticated() &&
                        <h1>
                            {message}
                        </h1>
                    }

                </div>
            </div>
        )
    }
}

export default Forge;