import React from 'react';
import './loader.scss';

const Loaders = (props) => {
    return (
        <div className="sk-double-bounce" style={{visibility: props.loading ? 'visible' : 'hidden'}}>
            <div className="sk-child sk-double-bounce1"></div>
            <div className="sk-child sk-double-bounce2"></div>
        </div>
    )
};

export default Loaders;