import React, {Component} from 'react';

class Home extends Component {
    login() {
        this.props.auth.login();
    }

    render() {
        const {isAuthenticated} = this.props.auth;
        return (
            <div className="uk-container">
                {
                    isAuthenticated() && (
                        <div className="uk-section uk-dark uk-animation-fade">
                            <div className="uk-container">
                                <p>
                                    You are signed in.
                                </p>
                            </div>
                        </div>
                    )
                }
                {
                    !isAuthenticated() && (
                        <div className="uk-section uk-dark uk-animation-fade">
                            <div className="uk-container">
                                <p>
                                    You are not logged in! Please&nbsp;
                                    <a style={{cursor: 'pointer'}} onClick={this.login.bind(this)}>
                                        sign in
                                    </a> to continue.
                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default Home;
