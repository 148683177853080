import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import NavLink from './nav_link';
import {defaults} from 'react-chartjs-2';

import './app.scss';
import xd_Logo from './app.svg';

defaults.global.defaultFontColor = '#fff';
defaults.global.defaultColor = '#fff';
defaults.global.defaultFontSize = 12;
defaults.global.defaultFontFamily = "'Artifakt Element','Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

defaults.global.elements.line.backgroundColor = 'rgba(255,255,255,0.2)';
defaults.global.elements.line.borderColor = 'rgba(255,255,255,1)';
defaults.global.elements.line.borderWidth = 1;

defaults.global.elements.rectangle.backgroundColor = 'rgba(255,255,255,0.2)';
defaults.global.elements.rectangle.borderColor = 'rgba(255,255,255,1)';
defaults.global.elements.rectangle.borderWidth = 0;

defaults.global.elements.arc.borderWidth = 0;

defaults.scale.ticks.beginAtZero = false;
defaults.scale.gridLines.display = false;
defaults.scale.gridLines.color = 'rgba(255,255,255,.2)';

defaults.global.responsive = true;
defaults.global.maintainAspectRatio = false;

defaults.global.legend.position = 'bottom';
defaults.global.legend.display = false;
defaults.global.legend.labels.padding = 20;
defaults.global.legend.labels.boxWidth = 10;

const navElements = [
    {
        "item": "Identity",
        "link": "/identity",
    }, {
        "item": "Forge",
        "link": "/forge",
    }];


class App extends Component {

    login() {
        this.props.auth.login();
    }

    logout() {
        this.props.auth.logout();
    }

    render() {
        const {isAuthenticated} = this.props.auth;

        if (isAuthenticated() && window.location.pathname === '/') {
            window.location.href = '/home';
        }

        return (
            <div>
                <header
                    data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-dark; top: 100">
                    <nav className={"uk-navbar-container xd__navbar-container uk-visible@s"}
                         data-uk-navbar>
                        <div className="uk-navbar-left">
                            <Link className={"uk-navbar-item uk-logo xd-logo"} to="/">
                                <img data-src={xd_Logo} width="166.656" height="28" alt="Logo" data-uk-img/>
                            </Link>
                        </div>
                        <div className={"uk-navbar-right uk-margin-right"}>
                            {
                                !isAuthenticated() && (
                                    <ul className={"uk-navbar-nav"}>
                                        <li>
                                            <a className={"qsLoginBtn"} onClick={this.login.bind(this)}>
                                                Sign in
                                            </a>
                                        </li>
                                    </ul>
                                )
                            }
                            {
                                isAuthenticated() && (
                                    <ul className={"uk-navbar-nav"}>
                                        {navElements.map((data) => {
                                            return (
                                                <NavLink to={data.link} key={data.item}>
                                                    {data.item}
                                                </NavLink>
                                            )
                                        })}


                                        <li>
                                            <a href="#"><span data-uk-icon="user"></span></a>
                                            <div className="uk-navbar-dropdown">
                                                <ul className="uk-nav uk-navbar-dropdown-nav">
                                                    <li><a href="profile">Profile</a></li>
                                                    <li>
                                                        <a className={"qsLogoutBtn"} onClick={this.logout.bind(this)}>
                                                            Sign out
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                )
                            }

                        </div>
                    </nav>

                    <nav className={"uk-navbar-container xd__navbar-container uk-hidden@s"} data-uk-navbar>
                        <div className={"uk-navbar-left uk-dark"}>
                            <ul className={"uk-navbar-nav"}>
                                <li className={"uk-flex-inline"}>
                                    <a href="" aria-label={"Hamburger icon"} data-uk-toggle="target: #offcanvas">
                                        <span className={"uk-light"} data-uk-icon="icon: menu; ratio: 2"></span>
                                    </a>
                                    <Link className={"uk-navbar-item uk-logo xd-logo uk-padding-remove"} to="/">
                                        <img data-src={xd_Logo} width="166.656" height="28" alt="Logo" data-uk-img/>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <div id={"offcanvas"} data-uk-offcanvas="mode: reveal; overlay: true">
                        <div className={"uk-offcanvas-bar uk-flex uk-flex-column"}>
                            <button className={"uk-offcanvas-close"} type="button" data-uk-close></button>
                            {
                                !isAuthenticated() && (
                                    <ul className={"uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical"}>
                                        <li>
                                            <a className={"qsLoginBtn"} onClick={this.login.bind(this)}>
                                                Sign in
                                            </a>
                                        </li>
                                    </ul>
                                )
                            }
                            {
                                isAuthenticated() && (
                                    <ul className={"uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical"}>
                                        {navElements.map((data) => {
                                            return (
                                                <NavLink to={data.link} key={data.item}>
                                                    {data.item}
                                                </NavLink>
                                            )
                                        })}
                                        <li>
                                            <a className={"qsLogoutBtn"} onClick={this.logout.bind(this)}>
                                                Sign out
                                            </a>
                                        </li>
                                    </ul>
                                )
                            }

                        </div>
                    </div>

                </header>

                {
                    !isAuthenticated() && window.location.pathname == '/' && (
                        <div className="uk-container">
                            <h1>Public page</h1>
                        </div>
                    )
                }

            </div>
        );
    }
}

export default App;
