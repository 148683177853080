import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';

import './charts.scss';
import Loader from "../loader/loader";

const chartLoadTime = 1000;

const shades = (data) => {
    let arrayData = [];
    let maxOpacity = .75;
    let deltaOpacity = (maxOpacity - 0) / data.length;

    for (let index = data.length; index > 0; index--) {
        arrayData.push('rgba(255, 255, 255,' + deltaOpacity * index + ')');
    }
    return arrayData;
};

const hover = (data) => {
    let arrayData = [];

    for (let index = data.length; index > 0; index--) {
        arrayData.push('rgba(255, 255, 255, 1)');
    }
    return arrayData;
};

class ChartDonut extends Component {

    state = {
        loading: true,
        data: [],
        labels: []
    };

    componentDidMount() {

        setTimeout(() =>
                this.setState({
                    loading: false,
                    data: [300, 50, 100],
                    labels: ['Red', 'Green', 'Yellow']
                })
            , chartLoadTime)

    }

    render() {
        return (
            <div className={this.state.loading ? 'uk-inline' : ''}>
                <Loader loading={this.state.loading}/>
                <div className="uk-padding xd-chart-container"
                     style={{visibility: this.state.loading ? 'hidden' : 'visible'}}>
                    <h5 className={"uk-text-left uk-margin-remove uk-text-bold uk-text-uppercase"}>
                        {this.props.title}
                    </h5>
                    <p className={"uk-text-left uk-margin-remove-top"}>
                        {this.props.subtitle}
                    </p>
                    <Doughnut data={{
                        labels: this.state.labels,
                        datasets: [{
                            data: this.state.data,
                            backgroundColor: shades(this.state.data),
                            hoverBackgroundColor: hover(this.state.data)
                        }]
                    }} options={{
                        legend: {
                            display: true
                        },
                        cutoutPercentage: 50
                    }}/>
                </div>
            </div>
        )
    }
}

export default ChartDonut;