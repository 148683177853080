import React, {Component} from 'react';

class Profile extends Component {

    constructor() {
        super();
        this.state = {
            profile: {}
        }
    }

    componentDidMount() {
        const {userProfile, getProfile} = this.props.auth;
        if (!userProfile) {
            getProfile((err, profile) => {
                this.setState({profile});
            });
        } else {
            this.setState({profile: userProfile});
        }
    }

    render() {
        const {profile} = this.state;
        return (
            <div className="uk-container">
                <div>
                    <img className="uk-border-circle" width="32" height="32" src={profile.picture} alt="profile"/>
                    <span className="uk-margin-small-left">{profile.name}</span>
                    <span data-uk-icon="chevron-down"></span>
                    <pre>{JSON.stringify(profile, null, 2)}</pre>
                </div>
            </div>
        );
    }
}

export default Profile;
